
import {
  defineComponent,
  ref,
  provide,
  watch,
  reactive,
  readonly,
  watchEffect,
  nextTick,
  onBeforeUpdate,
  toRefs,
  onMounted,
} from "vue";
import { RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import axios from "@/api/axios";
import { useStore } from "vuex";
import $ from "jquery";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
import wx from "weixin-sdk-js";
import { wechatLoginMine } from "@/api/wechatLogin";
import Toast from "@/components/UI/Toast/";

export default defineComponent({
  name: "firserOrder",
  setup() {


    return {
       
    };
  },
});
