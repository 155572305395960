<template>
  <div class="wrapper">
    <div class="header">
        <img style="width: 100%;"  src="@/assets/images/firstA.jpg" alt=""/>
    </div>
</div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  provide,
  watch,
  reactive,
  readonly,
  watchEffect,
  nextTick,
  onBeforeUpdate,
  toRefs,
  onMounted,
} from "vue";
import { RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import axios from "@/api/axios";
import { useStore } from "vuex";
import $ from "jquery";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
import wx from "weixin-sdk-js";
import { wechatLoginMine } from "@/api/wechatLogin";
import Toast from "@/components/UI/Toast/";

export default defineComponent({
  name: "firserOrder",
  setup() {


    return {
       
    };
  },
});
</script>


<style lang="scss" scoped>
.wrapper{
    width: 100%;
    height: 100%;
}

#wrapper:before {
    content: '';
    display: block;
}

.header{
    width: 100%;
    max-width: 100%;
    text-align: center;
    // position: fixed;
    // transform: translateY(-50%);
}



</style>
